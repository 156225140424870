/**
 * Type: コンポーネント
 * What: パンくずリスト
 */
import { Link } from 'gatsby';
import React from 'react';
import TruncateFunc from '../util/truncate';

const BreadcrumbComponent = ({
  hierarchy,
  bread2title,
  bread2url,
  bread3title,
  bread3url,
  bread4title,
  // eslint-disable-next-line no-unused-vars
}) => (
  <nav
    className="breadcrumb"
    aria-label="breadcrumbs"
  >
    <ul>
      <li>
        <Link to="/" aria-current="page">
          TOP
        </Link>
      </li>
      {hierarchy === 2 && (
        <li className="is-active">
          <span>{TruncateFunc(bread2title, 32)}</span>
        </li>
      )}
      {hierarchy === 3 && (
        <>
          <li>
            <Link to={`/${bread2url}`}>
              {TruncateFunc(bread2title, 16)}
            </Link>
          </li>
          <li className="is-active">
            <span>{TruncateFunc(bread3title, 32)}</span>
          </li>
        </>
      )}
      {hierarchy === 4 && (
        <>
          <li>
            <Link to={`/${bread2url}`}>
              {TruncateFunc(bread2title, 16)}
            </Link>
          </li>
          <li>
            <Link to={`/${bread3url}`}>
              {TruncateFunc(bread3title, 16)}
            </Link>
          </li>
          <li className="is-active">
            <span>{TruncateFunc(bread4title, 32)}</span>
          </li>
        </>
      )}
    </ul>
  </nav>
);

export default BreadcrumbComponent;
